@mixin button($color, $background, $uppercase: false) {
    font-family: $workSans;
    display: inline-block;
    padding: 12px 27px;
    border-radius: 10px;
    border: 3px solid $background;
    background-color: $background;
    color: $color;
    @include fontsize(16);
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: .2s all ease-out;

    @if ($uppercase == true) {
        text-transform: uppercase;
    }

    &:hover {
        background-color: transparent;
        color: $background;
    }

    @include medium {
        @include fontsize(14);
    }
}

/* --------------------------------------------------------
-- Button primary
-------------------------------------------------------- */

.button {
    position: relative;

    display: inline-block;

    padding: 9px 16px;
    
    color: $white;
    // border: 1px solid $action-color;
    background: $gradient-action;

    transition: all var(--transition-config);

    overflow: hidden;

    text-transform: uppercase;

    .button__append {
        display: none;
        position: relative;
        svg, path{fill: currentcolor;}
    }

    .button__body {
        position: relative;
    }

    &:hover {
        color: $action-color;
        border-color: $action-color;
    }

    &.--gradient{border-color: transparent; background: $gradient-dark-blue;}

    // Secondary variation : border and transparent background
    ////////////////////////////////////////////////////////////
    &.--secondary {
        background: transparent;
        color: $action-color;

        padding: 8px 16px;

        border: solid 1px $action-color;

        &:before {
            background-color: $action-color;
        }

        &:hover {
            color: $white;
        }

        //colors variations
        &.--c-white {
            color: $white;
            border-color: $white;

            &:before {
                background-color: $white;
            }

            &:hover {
                color: $action-color;

                .button__append__path {
                    fill: $action-color;
                }
            }
        }


        &.--c-action {
            color: $action-color;
            border-color: $action-color;

            .button__append__path {
                fill: $action-color;
            }

            &:hover {
                color: $white;

                .button__append__path {
                    fill: $white;
                }
            }
        }
    }

    &.--info{
        color: $text-primary;
        background: $pale-green; border-color: $pale-green;
        .button__append {
            color: $info-color;
        }
        &:hover {
            color: $info-color;
            border-color: $info-color;
        }
    }

    // Append variation
    ////////////////////////////////////////////////////////////
    &.--append {
        display: inline-flex; align-items: center; justify-content: space-between;
        // white-space: nowrap;
        padding: 8px;

        .button__body{text-align: left;}
        .button__append {
            display: block; margin-left: 8px;
            svg{display: block;}
        }
    }

    // fullwidth
    ////////////////////////////////////////////////////////////

    &.--fullwidth {
        display: block; width: 100%;

        text-align: center;

        padding: 1rem;
        &+&{margin-top: 0.5rem;}
        &.--append{display: flex;}
    }
    &.--small{
        padding: 0.25rem 0.5rem;
    }
}

/* --------------------------------------------------------
-- Box button
-------------------------------------------------------- */

.boxButton a {

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 150px;
    width: 100%;
    min-height: 150px;
    height: 100%;
    padding: 1rem 0.5rem;

    color: $white; text-align: center;

    background: $gradient-action;
    background-size: 100%;

    font-size: 1.5rem;
    text-transform: uppercase;

    transition: color var(--transition-config);

    overflow: hidden;

    &:hover {
        color: $action-color;

        .boxButton__icon {
                
            path {
                fill: $action-color;
            }
        }
    }
}

.boxButton__icon {
    position: relative;

    margin-bottom: 0.5rem;
}

.boxButton__title {
    position: relative;
}

@include tablet {

    .boxButton a {
        flex-direction: row-reverse;
        align-items: center;

        min-height: auto;

        padding: 1rem;
    }

    .boxButton__icon {
        display: flex;
        align-items: center;

        margin-bottom: 0;
        margin-left: 1rem;
    }
}

/* --------------------------------------------------------
-- Base Button
-------------------------------------------------------- */

.baseButton {
    font-size: var(--fs-small);
    color: $action-color;
}