/**************** grid ***************/
	.grid{margin-left: 0; margin-right: 0;}
    .grid:before,
    .grid:after {content: " "; display: table;}
    .grid:after {clear: both;}
	.grid [class*="col-"]{float: left; width: 100%; position: relative;}
	.grid .right[class*="col-"]{float: right;}
    .grid__padding-8 {margin-left: -8px; margin-right: -8px;}
    .grid__padding-10 {margin-left: -10px; margin-right: -10px;}
    .grid__padding-15 {margin-left: -15px; margin-right: -15px;}
    .grid__padding-25 {margin-left: -20px; margin-right: -20px;}
    .grid__padding-35 {margin-left: -20px; margin-right: -20px;}
    .grid__padding-8.grid [class*="col-"] {padding-left: 8px; padding-right: 8px;}
    .grid__padding-10.grid [class*="col-"] {padding-left: 10px; padding-right: 10px;}
    .grid__padding-15.grid [class*="col-"] {padding-left: 15px; padding-right: 15px;}
    .grid__padding-25.grid [class*="col-"] {padding-left: 20px; padding-right: 20px;}
    .grid__padding-35.grid [class*="col-"] {padding-left: 20px; padding-right: 20px;}


	/* All device */
		.grid .col-10{width: 10%;}
		.grid .col-offset-10{margin-left: 10%;}
		.grid .col-20{width: 20%;}
		.grid .col-offset-20{margin-left: 20%;}
		.grid .col-25{width: 25%;}
		.grid .col-offset-25{margin-left: 25%;}
		.grid .col-30{width: 30%;}
		.grid .col-offset-30{margin-left: 30%;}
		.grid .col-33{width: 33.33333333%;}
		.grid .col-offset-33{margin-left: 33.33333333%;}
		.grid .col-40{width: 40%;}
		.grid .col-offset-40{margin-left: 40%;}
		.grid .col-50{width: 50%;}
		.grid .col-offset-50{margin-left: 50%;}
		.grid .col-60{width: 60%;}
		.grid .col-offset-60{margin-left: 60%;}
        .grid .col-66{width: 66.66666666%;}
        .grid .col-offset-66{margin-left: 66.66666666%;}
		.grid .col-70{width: 70%;}
		.grid .col-offset-70{margin-left: 70%;}
		.grid .col-75{width: 75%;}
		.grid .col-offset-75{margin-left: 75%;}
		.grid .col-80{width: 80%;}
		.grid .col-offset-80{margin-left: 80%;}
		.grid .col-100{width: 100%;}
		.grid .col-offset-100{margin-left: 100%;}

    /* Extra-Small devices (mobile, < 768px) */
        @media (max-width: 767px) {
            .grid .col-xs-10{width: 10%;}
            .grid .col-offset-xs-10{margin-left: 10%;}
            .grid .col-xs-20{width: 20%;}
            .grid .col-offset-xs-20{margin-left: 20%;}
            .grid .col-xs-25{width: 25%;}
            .grid .col-offset-xs-25{margin-left: 25%;}
            .grid .col-xs-30{width: 30%;}
            .grid .col-offset-xs-30{margin-left: 30%;}
            .grid .col-xs-33{width: 33.33333333%;}
            .grid .col-offset-xs-33{margin-left: 33.33333333%;}
            .grid .col-xs-40{width: 40%;}
            .grid .col-offset-xs-40{margin-left: 40%;}
            .grid .col-xs-50{width: 50%;}
            .grid .col-offset-xs-50{margin-left: 50%;}
            .grid .col-xs-60{width: 60%;}
            .grid .col-offset-xs-60{margin-left: 60%;}
            .grid .col-xs-66{width: 66.66666666%;}
            .grid .col-offset-xs-66{margin-left: 66.66666666%;}
            .grid .col-xs-70{width: 70%;}
            .grid .col-offset-xs-70{margin-left: 70%;}
            .grid .col-xs-75{width: 75%;}
            .grid .col-offset-xs-75{margin-left: 75%;}
            .grid .col-xs-80{width: 80%;}
            .grid .col-offset-xs-80{margin-left: 80%;}
            .grid .col-xs-100{width: 100%;}
            .grid .col-offset-xs-100{margin-left: 100%;}
        }

    /* Small devices (tablets, 768px and up) */
		@media (min-width: 768px) {
			.grid .col-sm-10{width: 10%;}
			.grid .col-offset-sm-10{margin-left: 10%;}
			.grid .col-sm-20{width: 20%;}
			.grid .col-offset-sm-20{margin-left: 20%;}
			.grid .col-sm-25{width: 25%;}
			.grid .col-offset-sm-25{margin-left: 25%;}
			.grid .col-sm-30{width: 30%;}
			.grid .col-offset-sm-30{margin-left: 30%;}
			.grid .col-sm-33{width: 33.33333333%;}
			.grid .col-offset-sm-33{margin-left: 33.33333333%;}
			.grid .col-sm-40{width: 40%;}
			.grid .col-offset-sm-40{margin-left: 40%;}
			.grid .col-sm-50{width: 50%;}
			.grid .col-offset-sm-50{margin-left: 50%;}
			.grid .col-sm-60{width: 60%;}
			.grid .col-offset-sm-60{margin-left: 60%;}
            .grid .col-sm-66{width: 66.66666666%;}
            .grid .col-offset-sm-66{margin-left: 66.66666666%;}
			.grid .col-sm-70{width: 70%;}
			.grid .col-offset-sm-70{margin-left: 70%;}
			.grid .col-sm-75{width: 75%;}
			.grid .col-offset-sm-75{margin-left: 75%;}
			.grid .col-sm-80{width: 80%;}
			.grid .col-offset-sm-80{margin-left: 80%;}
			.grid .col-sm-100{width: 100%;}
			.grid .col-offset-sm-100{margin-left: 100%;}
		}

	/* Medium devices (desktops, 992px and up) */
		@media (min-width: 992px) {
			.grid .col-md-10{width: 10%;}
			.grid .col-offset-md-10{margin-left: 10%;}
			.grid .col-md-20{width: 20%;}
			.grid .col-offset-md-20{margin-left: 20%;}
			.grid .col-md-25{width: 25%;}
			.grid .col-offset-md-25{margin-left: 25%;}
			.grid .col-md-30{width: 30%;}
			.grid .col-offset-md-30{margin-left: 30%;}
			.grid .col-md-33{width: 33.33333333%;}
			.grid .col-offset-md-33{margin-left: 33.33333333%;}
			.grid .col-md-40{width: 40%;}
			.grid .col-offset-md-40{margin-left: 40%;}
			.grid .col-md-50{width: 50%;}
			.grid .col-offset-md-50{margin-left: 50%;}
			.grid .col-md-60{width: 60%;}
			.grid .col-offset-md-60{margin-left: 60%;}
            .grid .col-md-66{width: 66.66666666%;}
            .grid .col-offset-md-66{margin-left: 66.66666666%;}
			.grid .col-md-70{width: 70%;}
			.grid .col-offset-md-70{margin-left: 70%;}
			.grid .col-md-75{width: 75%;}
			.grid .col-offset-md-75{margin-left: 75%;}
			.grid .col-md-80{width: 80%;}
			.grid .col-offset-md-80{margin-left: 80%;}
			.grid .col-md-100{width: 100%;}
			.grid .col-offset-md-100{margin-left: 100%;}
		}

	/* Large devices (large desktops, 1200px and up) */
		@media (min-width: 1025px) {
            .grid__padding-25 {margin-left: -25px; margin-right: -25px;}
            .grid__padding-35 {margin-left: -35px; margin-right: -35px;}
            .grid__padding-25.grid [class*="col-"] {padding-left: 25px; padding-right: 25px;}
            .grid__padding-35.grid [class*="col-"] {padding-left: 35px; padding-right: 35px;}

			.grid .col-lg-10{width: 10%;}
			.grid .col-offset-lg-10{margin-left: 10%;}
			.grid .col-lg-20{width: 20%;}
			.grid .col-offset-lg-20{margin-left: 20%;}
			.grid .col-lg-25{width: 25%;}
			.grid .col-offset-lg-25{margin-left: 25%;}
			.grid .col-lg-30{width: 30%;}
			.grid .col-offset-lg-30{margin-left: 30%;}
			.grid .col-lg-33{width: 33.33333333%;}
			.grid .col-offset-lg-33{margin-left: 33.33333333%;}
			.grid .col-lg-40{width: 40%;}
			.grid .col-offset-lg-40{margin-left: 40%;}
			.grid .col-lg-50{width: 50%;}
			.grid .col-offset-lg-50{margin-left: 50%;}
			.grid .col-lg-60{width: 60%;}
			.grid .col-offset-lg-60{margin-left: 60%;}
            .grid .col-lg-66{width: 66.66666666%;}
            .grid .col-offset-lg-66{margin-left: 66.66666666%;}
			.grid .col-lg-70{width: 70%;}
			.grid .col-offset-lg-70{margin-left: 70%;}
			.grid .col-lg-75{width: 75%;}
			.grid .col-offset-lg-75{margin-left: 75%;}
			.grid .col-lg-80{width: 80%;}
			.grid .col-offset-lg-80{margin-left: 80%;}
			.grid .col-lg-100{width: 100%;}
			.grid .col-offset-lg-100{margin-left: 100%;}
		}

	.showMobile{display: none!important;}
	.hideMobile{display: block!important;}
	@media (max-width: 768px) {
		.showMobile{display: block!important;}
		.hideMobile{display: none!important;}
	}