
.vanilla-calendar-day{padding: 5px;}
.vanilla-calendar-day__btn{border-radius: 100%;}
.vanilla-calendar-day__btn_today{
    position: relative;
    border: none;
    &:before{
        content: "";
        position: absolute; top: 100%; right: 0; left: 0;
        width: 100%; height: 2px; background: $green;
    }
}
.vanilla-calendar-day__btn.active{
    color: $dark-blue; background: transparent; border: 1px solid $dark-blue;
}
.vanilla-calendar-day__btn_selected.active{color: $white; background: $dark-blue; border: 1px solid $dark-blue;}