.b11Timeline__title{}
.b11TimelineMilestone{}
.b11TimelineNestedMilestones{margin: 1rem 0;}
.b11TimelineMilestone{}
.b11TimelineMilestone__title{
	margin-bottom: 1rem;
	font-size: var(--fs-h3); font-weight: 500; color: $info-color;
}
.b11TimelineNestedMilestone{
	position: relative;
	display: flex; justify-content: space-between; align-items: stretch; gap: 1rem;
}
.b11TimelineNestedMilestone__label{
	--labelWidth: 150px;
	position: relative;
	flex-shrink: 0;
	position: relative;
    display: flex; align-items: center; justify-content: stretch;
	width: var(--labelWidth); overflow: hidden;
	font-size: var(--fs-h4); text-align: center;
}
.b11TimelineNestedMilestone__label-text{
	position: relative; 
	width: 100%;  padding: 1rem .5rem;
	&:before,
	&:after{
		content: "";
		position: absolute; 
		width: 1px; height: 100vh;
		background: $info-color;
	}
	&:before{bottom: 100%; left: calc(var(--labelWidth) / 2);}
	&:after{top: 100%; left: calc(var(--labelWidth) / 2);}
}
.b11TimelineNestedMilestone__card{
	flex-grow: 1;
	margin: 1rem 0;
	.--cards &{
		background: $white; box-shadow: $shadow;
	}
}
.b11TimelineNestedMilestone__content{
	display: flex; justify-content: flex-start; align-items: flex-start; gap: 1rem;
	padding: 1rem;
}
.b11TimelineNestedMilestone__picture{
	flex-shrink: 0;
	display: block; width: 160px; height: 160px;
	img{display: block; width: 100%; height: 100%; object-fit: cover;}
}
.b11TimelineNestedMilestone__tag{
	display: inline-block; padding: 0.5rem 1rem;
	font-size: var(--fs-extra-small); color: $info-color; 
	background: $pale-green;
}
.b11TimelineNestedMilestone__title{
	margin: 0.5rem 0;
	font-size: var(--fs-h3);
}
.b11TimelineNestedMilestone__description{}
.b11TimelineNestedMilestone__buttons{
	display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap; gap: .5rem;
	padding: 1rem;
	border-top: 1px solid $light-blue-3;
}

@include tablet {
	.b11TimelineNestedMilestone{gap: 0; flex-direction: column;}
	.b11TimelineNestedMilestone__label{
		align-items: flex-start;
    	padding: 1.5rem 0;
	}
}
@include tablet-sm {
	.b11TimelineNestedMilestone__card{margin: 0;}
	.b11TimelineNestedMilestone__content{flex-direction: column;}
}