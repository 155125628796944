.sidebar{padding-top: $spacing-md; padding-bottom: $spacing-md;}

.sidebarThumbnail{
    box-shadow: $shadow;
    margin-bottom: 0.5rem;
    img{display: block;}
}

.sidebar__section{
    &+&{margin-top: 1rem;}
}
.sidebar__block{
    padding: 1rem;
    background: $white; box-shadow: $shadow;
    .sidebarThumbnail{box-shadow: none;}
}
.sidebar__title{
    display: flex; justify-content: space-between; align-items: flex-start; gap: .5rem; 
    font-size: var(--fs-h3);
}
.sidebar__title+.sidebar__content{margin-top: .5rem;}
.sidebar__description{margin-bottom: 1rem;}
.sidebar__row{
    display: flex; justify-content: space-between; align-items: flex-start; gap: 1rem;
    &+&{margin-top: 0.5rem;}
}

.sidebar__titleMobileSeeMore{display: none;}
.sidebar__titleMobileCarret{display: none;}
@include tablet {
    .sidebar{padding-top: $spacing-sm; padding-bottom: $spacing-sm;}
    .sidebar__section.--firstSidebarItem{
        z-index: 1000;
        position: fixed; bottom: 0; right: 0; left: 0;
        padding: 1rem;
        background: $white; 
        box-shadow: $shadow-darker;
        .sidebar__title{cursor: pointer;}
        .sidebar__content{display: none; margin-top: 1rem;}
        .sidebar__titleMobileSeeMore{display: block;}
        .sidebar__titleMobileCarret{
            display: block; 
            transform: rotate(90deg);
            transition: transform .25s;
        }
        .--open{
            .sidebar__titleMobileCarret{transform: rotate(-90deg);}
        }
    }
    .sidebarThumbnail{display: none;}
}
