/* --------------------------------------------------------
-- Events
-------------------------------------------------------- */
.events__inner {display: flex; justify-content: center; align-items: stretch; gap: 2rem;}
.events__inner-right {flex-grow: 1;}
.events__calendar {width: var(--sidebar-width);flex-shrink: 0;}

.events__calendar__el {
    width: 100%; padding: 0;
    box-shadow: $shadow;
}
.events__items {flex-grow: 1;}

.events__items.--limited {

    .eventItem {
        &:nth-child(n+4){
            display: none;
        }
    }
}
.events__more.--hided {display: none;}
.events__more button {
    margin-top: 1rem;
    color: $action-color;
    font-size: 0.75rem;
}

.events__calendar__mobile {width: 100%;}

.eventItem__noresult {
    padding: 2rem 1rem;
    text-transform: uppercase;
    box-shadow: $shadow;
}

@include tablet {
    .events__inner{flex-direction: column;}
    .events__calendar {display: none; width: 100%;}
    .events__items {width: 100%;}
    .eventItem__title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    .eventItem__excerpt {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
}
@include tablet-min {
    .events__calendar__mobile {
        display: none;
    }
}