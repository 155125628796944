.b17Image{}
.b17Image__container{position: relative; height: 340px; overflow: hidden;}
.b17ImageCard__bg{
    position: absolute; top: 0; right: 0; bottom: 0; left: 0;
    width: 100%; height: 100%;
    img{display: block; width: 100%; height: 100%; object-fit: cover; object-position: 50% 50%;}
    &:before{
        content: "";
        position: absolute; top: 0; right: 0; bottom: 0; left: 0;
        background: rgba(12, 59, 64, 0.75); backdrop-filter: blur(5px);
    }
}
.b17ImageCard__visual{
    position: relative;
    width: auto; height: 100%;
    img{display: block; width: auto; height: 100%; margin: 0 auto;}
}