/* --------------------------------------------------------
-- Banner
-------------------------------------------------------- */

.banner {
    position: relative;

    background-color: $white;

    box-shadow: 0px 0px 30px 10px rgba(52, 131, 134, 0.05), 0px 0px 10px 0px rgba(14, 107, 110, 0.20);

    padding: 4rem;

    &.--small {
        padding: 0;

        .banner__inner {
            align-items: stretch;
        }

        .banner__content {
            padding: 1rem;
        }

        .banner__content__title {
            font-weight: 400;
            color: $text-primary;
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
        }

        .banner__actions {
            padding: 1rem;

            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            & > div {
                width: 100%;
            }

            &:before {
                content: "";
    
                position: absolute;
            
                top: 0;
                right: 0;
                bottom: 0;
    
                width: 130%;
    
                background-color: rgba($green, 0.20);
                clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);    
            }

            .button {
                width: 100%;
                text-align: center;

                padding-top: 1rem;
                padding-bottom: 1rem;
                
                white-space: nowrap;
            }
        }
    }

    &.--large {
        background-image: url('../../images/shape/shape-banner.svg');
        background-repeat: no-repeat;
        background-position: right bottom;
    }
}

.banner__inner {
    display: flex; justify-content: space-between; align-items: center;
    gap: 4rem;
}

.banner__content__title {
    font-weight: 700;
    font-size: 2rem;

    color: $green;
    
    margin-bottom: 1rem;
}

@include tablet-sm {

    .banner {
        padding: 2rem 1rem;

        &.--small {

            &:before {
                display: none;
            }

            .banner__actions {
                width: 100%;
            }
        }
    }

    .banner__inner {
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
    }

    .banner__actions {
        width: 100%;
    }
}