.section {
    width: 100%; 
    margin: $spacing 0;

    &.--padding-y {
        padding: $spacing 0;
    }

    &.--padding-bottom {
        padding-bottom: $spacing;
    }

    &.--padding-top {
        padding-top: $spacing;
    }
}

.sectionHeader {margin-bottom: 1.5rem;margin-top: 1.5rem;}
.sectionHeader__top {
    display: flex; align-items: center; justify-content: space-between; gap: 1rem;
}
.sectionHeader__left {
    flex-grow: 1;
    display: flex; flex-direction: column; justify-content: stretch; align-items: stretch;
}
.sectionHeader__right {}

.section__title{
    font-size: var(--fs-h2); text-transform: uppercase;

    &.--has-before {
        &:before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 30px;
            background: linear-gradient(0deg, #008D97 0.81%, rgba(0, 141, 151, 0) 101.26%);
            margin-right: 0.5rem;
            transform: rotate(20deg);
        }
    }
}
.section__description{margin-top: 1rem;}

.--sidebar{
    .section{margin-top: $spacing-md; margin-bottom: $spacing-md;}
    .section.--padding-y {padding-top: $spacing-md; padding-bottom: $spacing-md;}
}

@include tablet {
    .--sidebar{
        .section{margin-top: $spacing-sm; margin-bottom: $spacing-sm;}
        .section.--padding-y {padding-top: $spacing-md; padding-bottom: $spacing-md;}
    }
}
@include tablet-sm {
    .section {
        margin: $spacing-sm 0;

        &.--padding-y {
            padding: $spacing-sm 0;
        }

        &.--padding-bottom {
            padding-bottom: $spacing-sm;
        }
    
        &.--padding-top {
            padding-top: $spacing-sm;
        }
    }
    .--sidebar{
        .section{margin-top: $spacing-sm; margin-bottom: $spacing-sm;}
        .section.--padding-y {padding-top: $spacing-sm; padding-bottom: $spacing-sm;}
    }
}