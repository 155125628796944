/* --------------------------------------------------------
-- Document card
-------------------------------------------------------- */

.resourceCard {
    box-shadow: $shadow;

    & + .resourceCard {
        margin-top: 1rem;
    }
}

.resourceCard__header {
    padding: 1.5rem 2rem 0;
    margin-bottom: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.resourceCard__role {
    color: $green-infos;
    
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.resourceCard__content {
    padding: 0 2rem 1.5rem;
}

.resourceCard__title {
    margin-bottom: 0.5rem;
}

.resourceCard__infos {
    margin-top: 1rem;
}

.resourceCard__documents {
    margin-top: 1rem;
    color: $text-secondary;

    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.resourceCard__footer {
    padding: 1rem; 
    border-top: 1px solid #A9D8DD
}

@include tablet-sm {

    .resourceCard__header {
        padding: 1.5rem 1rem 0;
    }

    .resourceCard__content {
        padding: 0 1rem 1.5rem;
    }
}