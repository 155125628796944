.b15tiles{}
.b15tiles__cards{
    --gap: 1rem;
    --columnsCount:  4;
    display: flex; justify-content: flex-start; align-items: stretch; flex-wrap: wrap;
    gap: var(--gap);
    margin-top: 2rem;
}
.b15tilesCard{
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    width: calc((100% / var(--columnsCount)) - var(--gap) + (var(--gap) / var(--columnsCount)));
    min-height: 150px; padding: 1rem;
    text-align: center;
    background: $white; box-shadow: $shadow;
    transition: color 0.3s ease;
    transition-delay: .1s;
    &:before {
        content: "";
        background-color: $action-color;
    }
    &:hover{color: $white}
}
.b15tilesCard__content{position: relative;}
.b15tilesCard__title{
    margin-bottom: 0.5rem;
    font-weight: bold;
}
.b15tilesCard__subtitle{font-size: 0.75rem;}

.--sidebar .b15tiles__cards{--columnsCount:  3;}

@include tablet{
    .b15tiles__cards{--columnsCount:  3;}
    .--sidebar .b15tiles__cards{--columnsCount:  2;}
}
@include tablet-sm{
    .b15tiles__cards{--columnsCount:  2;}
    .--sidebar .b15tiles__cards{--columnsCount:  1;}
}
@include mobile{
    .b15tiles__cards{--columnsCount:  1;}
}