.formGroup {

    & + .formGroup {
        margin-top: 1rem;
    }
}

.formGroup__input {
    width: 100%;
    border: none;
    outline: none;
    padding: 1rem;

    color: $text-primary;

    ::placeholder {
        color: $text-secondary;
        font-style: normal;
    }

    &:focus {
        outline: none;
    }
}

/**************************************************
* AUTOFILL
**************************************************/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: $grey !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    background-color: transparent !important;
}

.customFile {
    margin-bottom: 1rem;

    .customFile__input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    
    .customFile__label {
        color: white;
        background-color: $green-infos;
        display: inline-block;
        cursor: pointer;
        padding: 0.5rem 1rem;
        border: solid 1px $green-infos;

        transition: all .3s ease;
    }
    
    &:focus + .customFile__label,
    .customFile__label:hover {
        background-color: transparent;
        color: $green-infos;
    }
}