/* --------------------------------------------------------
-- Search bar
-------------------------------------------------------- */

.searchBar {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: $white;

    display: flex;
    align-items: center;
    justify-content: center;

    display: none;
}

.searchBar__inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.searchBar__form {
    width: calc(100% - 80px);
}

.searchForm {
    display: flex;
    align-items: center;
}

.searchForm__input {
    display: flex;
    align-items: center;

    width: 100%;
}

.searchForm__input__icon {
    display: flex;
    align-items: center;

    cursor: pointer;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.searchForm__input__field {
    border: none;

    color: $info-color;

    margin-left: 0.5rem;
    margin-right: 0.5rem;

    width: 100%;

    &:focus {
        outline: none;
        border: none;
    }
}

.searchForm__button__submit {
    padding: 1rem;

    border: 1px solid $action-color;

    background-color: $white;

    color: $action-color;

    text-transform: uppercase;

    transition: color .3s ease;

    span {
        position: relative;
    }

    &:before {
        background-color: $action-color;
    }

    &:hover {
        color: $white;
    }
}

.searchBar__closeBtn {
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.2);
    }
}