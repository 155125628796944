/* --------------------------------------------------------
-- Title
-------------------------------------------------------- */

.heading {
    &.--h1 {
        font-size: var(--fs-h1);
    }

    &.--h2 {
        font-size: var(--fs-h2);
    }

    &.--h3 {
        font-size: var(--fs-h3);
    }

    &.--h4 {
        font-size: var(--fs-h4);
    }

    &.--h5 {
        font-size: var(--fs-h5);
    }

    &.--h6 {
        font-size: var(--fs-h6);
    }
}