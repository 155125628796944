/* --------------------------------------------------------
-- Cards
-------------------------------------------------------- */

.b01Cards{}
.b01Cards__link{}
.b01Cards__row{
	--gap: 16px;
	--columnsCount:  3;
	display: flex; justify-content: flex-start; align-items: stretch; flex-wrap: wrap;
	gap: var(--gap);
}
.b01Card{
	width: calc((100% / var(--columnsCount)) - var(--gap) + (var(--gap) / var(--columnsCount)));
	background: #FFF; box-shadow: $shadow;
}
.b01Card__content{padding: 16px;}
.b01Card__visual{
	position: relative;
	flex-shrink: 0;
	display: block; width: 100%; height: 150px;
	img{display: block; width: 100%; height: 100%; object-fit: cover;}
}
.b01Card__title{margin-bottom: 0.5rem;}
.b01Card__text{}

.b01Cards__row.vertical{
	.b01Cards__row{
		--gap: 16px;
		--columnsCount:  3;
		display: flex; justify-content: flex-start; align-items: stretch; flex-wrap: wrap;
		gap: var(--gap);
	}
	.card{
		flex-grow: 0;
		width: calc((100% / var(--columnsCount)) - var(--gap) + (var(--gap) / var(--columnsCount)));
		margin: 0;
		background: #FFF; box-shadow: $shadow;
	}
	.card__content{flex-direction: column;padding: 0;gap: 0;}
	.card__title{
		margin-top: 0;
	}
	.card__picture{width: 100%;}
	.card__infos {
		padding: 1rem;
	}
}

// .b01Cards__row.vertical{
// 	flex-direction: column;
// 	.card,
// 	.b01Card{
// 		display: flex; justify-content: flex-start; align-items: flex-start; gap: 1rem;
// 		padding: 1rem
// 	}
// 	.b01Card__visual{width: 150px; height: 150px;}
// 	.b01Card__content{padding: 0;}
// }

@include tablet-sm {
	.b01Cards__row{
		flex-direction: column;
		--columnsCount:  1;
	}
}

@include mobile {
	.b01Cards__row.vertical{
		.card,
		.b01Card{flex-direction: column;}
		.card__picture,
		.b01Card__visual{width: 100%;}
	}
}