.b08Slideshow{}

.b08Slideshow__slide{}
.b08SlideshowSlide{}
.b08SlideshowSlide__visuel{
	display: block; min-width: 260px;
	img{
		display: block; width: 100%; height: 100%;
		object-fit: cover;
	}
}
.b08SlideshowSlide__infos{
	z-index: 1; position: absolute; bottom: 0; right: 0; left: 0;
	padding: 2rem;
	color: $white;
	&:after{
		content: '';
		z-index: -1; position: absolute; right: 0; left: 0; bottom: 0;
		width: 100%; height: 260px;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%);
	}
}
.b08SlideshowSlide__title{margin-bottom: 0.5rem; font-size: 1,125rem;}
.b08SlideshowSlide__description{}

.b08Slideshow__control{
	position: absolute;
	display: flex; justify-content: center; align-items: center;
	width: 42px; height: 42px;
	border: 1px solid $white;
	&:after{display: none;}
	svg{all: unset; position: relative; fill: $white;}
	&:hover,
	&:focus-visible{
		svg,
		path{fill: $action-color;}
	}
}