.keyFigures {
    color: $white;
    background: $gradient-green;
}
.keyFigures__title{margin-bottom: 2rem; text-align: center;}
.keyFigures__grid {
    --items-per-line: 4;
    display: grid;
    grid-template-columns: repeat(var(--items-per-line), 1fr);
    gap: 1rem;
}
.keyFigures__item {
    position: relative;
    padding: 1rem 1.5rem; overflow: hidden;
    color: $text-primary;
    background-color: $white;
    &:before {
        content: "";
        position: absolute; top: -20px; right: -20px; bottom: -20px;
        width: 20%;
        background: rgba($green, 0.2); box-shadow: 0px 0px 4px 0px rgba($green, 0.2) inset;
        transform: rotate(-15deg);
    }
}
.keyFigures__item__figure {
    margin-bottom: 0.2rem;
    font-size: 3rem; font-weight: 700; color: $dark-green;
}
.--sidebar .keyFigures__grid{padding-right: 1rem; padding-left: 1rem;}
@include tablet-sm {
    .keyFigures__grid {
        --items-per-line: 2;
    }

    .keyFigures__item {
        padding: 1rem;
    }
}