.pagination {margin-top: 2rem; margin-bottom: 2rem;}
.pagination__container {}
.pagination__list{display: flex; justify-content: center; align-items: center; flex-wrap: wrap;}
.pagination__item {margin: 5px;}

.pagination__button {
    display: inline-flex; justify-content: center; align-items: center;
    width: 40px; height: 40px; padding: 0;
    font-size: 16px; line-height: 1; color: $green;
    background: transparent; border: 1px solid $green;
    svg {display: block; fill: currentColor;}
    .icon {display: block;}
    &.current,
    &:hover,
    &:focus-visible {color: $white; border-color: $action-color; background: $action-color;}
    &.current{text-decoration: underline; color: $action-color; background: transparent;}
    &.prev,
    &.next{
        color: $text-primary; border: none; background: transparent;
        &:hover,
        &:focus-visible{color: $green;}
    }
}

@include tablet-sm {
    .pagination__button{width: 30px; height: 30px;}
}