.postItem{
    display: flex; justify-content: flex-start; align-items: flex-start;
    background: $white; box-shadow: $shadow;
    &+&{margin-top: 1rem;}
}
.postItem__visual{
    flex-shrink: 0;
    position: relative;
    width: 160px; height: 160px;
    img{
        position: absolute; top: 0; right: 0; bottom: 0; left: 0;
        width: 100%; height: 100%; object-fit: cover;
    }
}
.postItem__content{
    padding: 1rem;
    &>*+*{margin-top: .5rem;}
}
.postItem__categories{display: flex; justify-content: flex-start; align-items: center; flex-wrap: wrap; gap: .5rem;}
.postItem__category{padding: 0.25rem 0.5rem; color: $info-color; background: $pale-green;}
.postItem__title{
    font-size: var(--fs-h3);
}
.postItem__summary{}