.eventItem {
    display: flex; align-items: stretch; gap: 1rem;
    padding: .5rem;
    background-color: $white; box-shadow: $shadow;
    &+&{margin-top: 1rem;}
    &:nth-child(2){            
        .eventItem__date {
            background-color: $light-blue;
        }
    }
    &:nth-child(3), &:nth-child(n+4){
        .eventItem__date {
            background-color: $light-blue-3;
        }
    }
    &.--not-active {opacity: 0.4;}
}

.eventItem__date {
    flex-shrink: 0;
    display: flex; justify-content: center; align-items: center;
    width: 80px; padding: 1rem;
    font-size: var(--fs-h4); font-weight: 700; text-align: center; color: $white;
    background-color: $green;
}
.eventItem__content{align-self: center;}
.eventItem__excerpt {margin-top: 5px;}


.eventItem.--small {
    box-shadow: none; padding: 0;
    .eventItem__title{font-size: var(--fs-medium);}
}