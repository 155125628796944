.b10Gallery__title{margin-bottom: 0.5rem;}
.b10Gallery__description{margin-bottom: 1rem;}
.b10Gallery__pictures{
	--gap: 1rem;
	--columnsCount:  3;
	display: flex; justify-content: flex-start; align-items: stretch; gap: var(--gap); flex-wrap: wrap;

}
.b10Gallery__picture{
 	width: calc((100% / var(--columnsCount)) - var(--gap) + (var(--gap) / var(--columnsCount)));
	picture{
		position: relative;
		display: block; padding-bottom: 110%; // height: 400px;
	} 	
	img{
		position: absolute; top: 0; right: 0; bottom: 0; left: 0; 
		width: 100%; height: 100%; object-fit: cover;
	}
}

.--sidebar{
	.b10Gallery__pictures{--columnsCount:  2;}
}
@include tablet-sm {
	.b10Gallery__pictures{
		--columnsCount:  2;
	}
	.b10Gallery__picture{
		// height: 200px;
	}
}

// @include mobile {
// 	.b10Gallery__pictures{
// 		--columnsCount:  1;
// 	}
// }