/* --------------------------------------------------------
-- Skew Hover
-------------------------------------------------------- */

.a__underlineHover {
    padding-bottom: 2px;

    background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
    background-size: 0% 0.1em;
    background-position: 100% 100%;
    background-repeat: no-repeat;

    transition: background-size 0.3s cubic-bezier(0.7, 0, 0.2, 1);;

    &:hover {
        background-size: 100% 0.1em;
        background-position: 0% 100%;
    }

    &.--c-action {
        background-image: linear-gradient(to right, rgba($action-color, 1), rgba($action-color, 1));
    }
}