/* --------------------------------------------------------
-- Post creation
-------------------------------------------------------- */

.formPost {
    padding: 2rem;

    box-shadow: $shadow;

    width: 100%;

    margin: 4rem auto;

    .acf-fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .acf-field-flexible-content {
        padding: 0;
        bottom: none;

        .acf-label {
            display: none;
        }
    }

    .acf-fields > .acf-field {
        padding: 0;

        border: none;
    }

    .acf-field {
        width: 100%;

        margin-bottom: 1rem !important;

        &[data-name="event_start_date"], &[data-name="event_end_date"], &[data-name="event_localization"], &[data-name="event_fees"]{
            width: calc(50% - 0.5rem) !important;

            @media(max-width: 767px){
                width: 100% !important;
            }
        }
    }

    .acf-field[data-name="flexible"]{

        .acf-label {
            display: none;
        }
        
        .acf-fields.-border {
            border: none;
        }

        .acf-fields > .acf-field:first-child {
            padding: 0;
            margin-bottom: 0 !important;
        }

        .acf-fc-layout-handle {
            display: none;
        }

        .layout {
            border: none;
        }
    }

    .acf-actions {
        display: none;
    }

    .acf-field input[type=text], .acf-field input[type=password], .acf-field input[type=date], .acf-field input[type=datetime], .acf-field input[type=datetime-local], .acf-field input[type=email], .acf-field input[type=month], .acf-field input[type=number], .acf-field input[type=search], .acf-field input[type=tel], .acf-field input[type=time], .acf-field input[type=url], .acf-field input[type=week], .acf-field textarea, .acf-field select, textarea {
        width: 100%;
        padding:1rem;
        border: solid 1px $light-blue;

        &:focus-visible {
            border-color: $action-color;
            outline: solid 1px $action-color;
        }
    }

    .acf-form-submit {

        input {
            @extend .button;
            
            border: none;

            transition: background 0.3s ease;

            border: solid 1px $action-color;

            &:hover {
                background: $white;
            }
        }
    }
}

.formPost__description {
    margin-top: 0.5rem;
}

.formPost__form {
    margin-top: 2rem;
}

#tinymce {

    p {
        line-height: 1.2;
        padding: 0;
    }
}