.card{
    position: relative;
    flex-grow: 1;
    margin: 1rem 0;
    background: $white; box-shadow: $shadow;
}
.card__content{
    display: flex; justify-content: flex-start; align-items: flex-start; gap: 1rem;
    padding: 1rem;
}
.card__postType{
    position: absolute; top: 1rem; left: 1rem;
    display: inline-block; padding: 0.25rem 0.5rem;
    font-size: var(--fs-extra-small); color: $info-color; 
    background: $pale-green;
}
.card__picture{
    flex-shrink: 0;
    display: block; width: 160px; height: 160px;
    img{display: block; width: 100%; height: 100%; object-fit: cover;}
}
.card__tag{
    display: inline-block; padding: 0.5rem 1rem;
    font-size: var(--fs-extra-small); color: $info-color; 
    background: $pale-green;
}
.card__title{
    margin: 0.5rem 0;
    font-size: var(--fs-h3);
}
.card__description{}
.card__buttons{
    display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap; gap: .5rem;
    padding: 1rem;
    border-top: 1px solid $light-blue-3;
}

@include tablet {
    .card__content{flex-wrap: wrap;}
    .card__infos{width: 100%;}
}