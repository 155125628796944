/* --------------------------------------------------------
-- Footer
-------------------------------------------------------- */

.footer {
	color: $white;
}

.footer__top {
	padding: 4rem 0;

	background: linear-gradient(97.43deg, #004F52 7.08%, #036B6F 116.48%);
}

.footerBox {
	--items-per-line: 3;

	display: grid;
	grid-template-columns: repeat(var(--items-per-line), 1fr);
	gap: 2rem;

	padding: 2rem;

	background: linear-gradient(69deg, #036266 45.73%, rgba(235, 254, 255, 0.00) 194.1%);

	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.footerBox__item {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	& + .footerBox__item {

		&:before {
			content: "";

			position: absolute;

			left: -1rem;
			top: 0;
			bottom: 0;

			width: 1px;
			
			background-color: $white;
		}
	}
}

.footerBox__item__title {
	margin-bottom: 0.5rem;
}

.footer__newsletter {
	margin-top: 1rem;
}

.footer__networks {
	display: flex;
	align-items: center;
	height: 53px;
	li {

		& + li {
			margin-left: 0.75rem;
		}

		a {
			display: block;

			transition: transform var(--transition-config);
			img{display: block;width: 24px;}
			&:hover {
				transform: scale(1.1);
			}
		}
	}
}

.footerNav {
	margin-top: 4rem;

	font-size: 0.875rem;
}

.footerNav__list {
	--items-per-line: 5;

	display: grid;
	grid-template-columns: repeat(var(--items-per-line), 1fr);
	gap: 1rem;
}

.footerNav__item__title {
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-weight: 700;

	&.--active {

		.footerNav__item__icon {
			transform: rotate(90deg);
		}
	}
}

.footerNav__item__icon {
	transition: transform var(--transition-config);
}

.footerNav__drop {
	margin-top: 1rem;

	li {

		& + li {
			margin-top: 1rem;
		}

		a {
			color: $white;
		}
	}
}

.footer__bottom {
	background-color: $dark-green;

	padding: 0.8rem 0;
}

.footer__bottom__inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.footer__links {
	font-size: 0.875rem;
	
	ul {
		display: flex;
		align-items: center;
	}

	li {

		& + li {
			margin-left: 1rem;
		}

		a {
			color: $white;
		}
	}
}

.footerBox__item__footer {
	margin-top: 1rem;
}

@include tablet-sm {
	
	.footerBox {
		--items-per-line: 1;

		padding: 1rem;
	}

	.footerBox__item {
		
		& + .footerBox__item {
	
			&:before {
				content: "";
	
				position: absolute;
	
				top: -1rem;
				left: 0;
				right: 0;
				bottom: auto;
	
				width: 100%;
				height: 1px;
				
				background-color: $white;
			}
		}
	}

	.footerBox__item__footer {
		margin-top: 1rem;
	}

	.footer__top {
		padding: 2rem 0;
	}

	.footerNav {
		margin-top: 2rem;
	}

	.footerNav__list {
		--items-per-line: 1;
	}

	.footerNav__drop {
		display: none;
	}

	.footerNav__item__title {
		cursor: pointer;
	}
}

@include tablet-sm-min {
	
	.footerNav__item__icon {
		display: none;
	}
}