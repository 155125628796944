/* Fonts */
$workSans: 'Work Sans', Arial, Helvetica, sans-serif;
$ubuntu: 'ubuntu', Arial, Helvetica, sans-serif;

/* Colors */
$black: #000000;
$white: #ffffff;

$dark-blue: #0F084B;
//
$dark-green: #004F52;
$dark-green-2: #006E72;
$green-infos: #00909D;
$green: #009BA2;
$green-2: #1CB2B9;
$light-blue: #71C4C9;
$light-blue-2: #8ECED3;
$light-blue-3: #A9D8DD;
$light-blue-4: #E2FCFF;

$pale-green: #F1FEFF;

$red: #ad1919;
$green-success: #198619;

$grey: #364344;
$light-grey: #DAFBFF;

$action-color: #22196B;

/* Gradient */
$gradient-light-blue: linear-gradient(268.45deg, #EBFDFF 12.31%, #DAFBFF 103.39%);
$gradient-green: linear-gradient(280.87deg, $dark-green -6.31%, #37C0C6 125.03%);
$gradient-green-dark: linear-gradient(97deg, #005D65 7.08%, #00616A 116.48%);
$gradient-dark-blue: linear-gradient(72deg, $dark-blue 0.94%, #322A7B 103.05%);
$gradient-action: linear-gradient(45.33deg, #22196B 10.87%, #4536BC 88.55%);

/* Body */
$text-primary: $grey; // ou #2F3636 ?
$text-secondary: #698C8C; // ou #4D6161 ?
$info-color: #00909D;
$background: $white;

/* Dimensions */
$innerWidth: 1240px;
$innerWidth-sm: 720px;
$innerPadding: 20px;
$innerPadding-mobile: 10px;
$spacing: 4rem;
$spacing-md: 3rem;
$spacing-sm: 2rem;

/* form */
$inputColor: $light-blue;

$shadow: 0px 2px 8px 0px rgba(40, 100, 102, 0.15);
$shadow-dark: 0px 2px 8px 0px rgba(40, 100, 102, 0.5);
$shadow-darker: 0px 0px 10px 0px rgba(40, 100, 102, 1);
$shadowInset: 0px 1px 4px 0px rgba(1, 83, 87, 0.20) inset;

:root {
    // container
    --container-padding: 15px;
    --container-width: calc(1140px + (var(--container-padding) * 2)); // 1140 + --container-padding*2

    // sidebar 
    --sidebar-width: 330px;

    // default
    --default-font-size: 1rem;
    --default-font-weight: 400;

    // transition
    --transition-speed: 0.5s;
    --transition-easing: ease;
    --transition-config: var(--transition-speed) var(--transition-easing);

    // title sizes
    --fs-h1: 2.625rem;  // 42px
    --fs-h2: 2rem;      // 32px
    --fs-h3: 1.5rem;    // 24px
    --fs-h4: 1.125rem;  // 18px

    // font sizes
    --fs-medium: 1.125rem;     // 18px
    --fs-small: 0.875rem;      // 14px
    --fs-extra-small: 0.75rem; // 12px

    @media(max-width: 500px) {
        --fs-h2: 1.5rem;    // 24px
        --fs-h3: 1.125rem;  // 18px
        --fs-h4: 1rem;      // 16px
    }
}