/* --------------------------------------------------------
-- Skew Hover
-------------------------------------------------------- */

.a__skewHover {
    
    --skew-value: 25;
    --skew: skew(25deg);
    --x-radian: calc((var(--skew-value) * pi) / 180);
    --skew-width: var(--item-height) * tan(var(--x-radian));

    position: relative;

    overflow: hidden;

    &:before {
        content: "";

        position: absolute;
        top: -1px;
        bottom: -1px;
        left: calc(var(--skew-width) * -1 - 1px);
        
        width: calc(100% + var(--skew-width));

        background-color: $white;

        transition: transform 0.5s cubic-bezier(0.7, 0, 0.2, 1);
        will-change: transform;

        transform: var(--skew) scaleX(0);
        transform-origin: top right;
    }

    &:hover {
        box-shadow: 0px 2px 8px 0px rgba(40,100,102, 0.35);
        &:before {
            transform: var(--skew) scaleX(1.01);
            transform-origin: top left;
        }
    }
}