.b13Grid{overflow: hidden;

    @include tablet {
        .boxButton a {
            height: auto;
        }
    }
}
.b13Grid__grid {
    --gap: 1rem;
    --columnsCount:  3;
    display: flex; justify-content: flex-start; align-items: stretch; flex-wrap: wrap;
    gap: var(--gap);
}

.b13GridItem {
    width: calc((100% / var(--columnsCount)) - var(--gap) + (var(--gap) / var(--columnsCount)));
	height: auto; padding: 1rem;
    background-color: $white; box-shadow: 0px 2px 8px 0px rgba($dark-green-2, 0.15);
    &.boxButton{padding: 0;}
}
.boxButton{flex-grow: 1;}

.b13GridItem__icon {margin-bottom: 1rem;}
.b13GridItem__title {margin-bottom: 0.5rem;}
.gridButton {margin-top: 1.5rem;}
.gridSlider{overflow: visible;
    .swiper-wrapper {
        height: auto;
    }
}

@include tablet {
    .b13Grid__grid {display: none;}
}

@include tablet-min {
    .gridSlider {display: none;}
    .gridButton {display: none;}
}