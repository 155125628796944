.b05Hero{}
.b05Hero__container{
	position: relative;
	display: flex; justify-content: center; align-items: center; flex-direction: column;
	min-height: 410px; padding: 2rem;
}
.b05Hero__background{
	z-index: 0; position: absolute; top: 0; right: 0; bottom: 0; left: 0;
	img{
		position: relative;
		width: 100%; height: 100%; object-fit: cover;
	}
	&:before{
		content: '';
		z-index: 1; position: absolute; top: 0; right: 0; bottom: 0; left: 0;
		background: linear-gradient(0deg, rgba(9, 25, 26, 0.30) 0%, rgba(9, 25, 26, 0.30) 100%);
	}
}
.b05Hero__content{
	position: relative;
	color: $white; text-align: center;
	background: var(--surface-gradient-blue);
}
.b05Hero__title{margin-bottom: 2rem;}