.wysiwyg {
    line-height: 1.6rem;
    p {
        margin-bottom: 1em;
        &:hover {text-decoration: none;}
    }
    b, strong {font-weight: 600}
    em, i {font-style: italic;}
    a {
        text-decoration: underline;
        &:hover {text-decoration: none;}
    }
    ul,
    ol {
        margin-top: 1em;
        margin-bottom: 1em;
        padding-left: 24px;
        // li+li{margin-top: 1em;}
        &:hover {text-decoration: none;}
    }
    ul li {list-style-type: disc;}
    ol li {list-style-type: decimal;}
    blockquote, q {
        padding: 1em 2em; margin: 1em 0;
        position: relative; color: rgba(0,0,0,0.7);
        background-color: rgba(215, 187, 163, 0.15);
        &::before {content: "«"}
        &::after {content: "»"}
        p {display: inline;}
    }
    h1 {margin-bottom: 1rem;   @extend .heading; @extend .--h1;}
    h2 {margin-bottom: .75rem; @extend .heading; @extend .--h2;}
    h3 {margin-bottom: .5rem;  @extend .heading; @extend .--h3;}
    h4 {margin-bottom: .25rem; @extend .heading; @extend .--h4;}
    h5 {margin-bottom: .25rem; @extend .heading; @extend .--h5;}
    h6 {margin-bottom: .25rem; @extend .heading; @extend .--h6;}

    table {
        width: 100%;

        td {
            padding: 1.5rem;
            text-align: center;
            vertical-align: middle;
            border: solid 1px $grey;

            p {
                margin-bottom: 0;

                & + p {
                    margin-top: 1rem;
                }
            }
        }
    }

    img {
        height: auto;
        &.alignleft {
            float: left;
            margin: 1rem;
        }

        &.alignright {
            float: right;
            margin: 1rem;
        }
    }
}