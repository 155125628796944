/* --------------------------------------------------------
-- Homepage
-------------------------------------------------------- */

// Introduction
//////////////////////////////////////////////////////
.homeIntro{color: $white; background: $green;}
.homeIntro__inner {
    display: flex; justify-content: space-between; align-items: center; gap: 4rem;flex-wrap: wrap;
    padding: 2rem 0;

}
.homeIntro__title{
    font-size: 2.6rem;
    font-weight: 500;
    width: calc(58% - 2rem);
}
.homeIntro__description{
    width: calc(42% - 2rem);
    max-width: 430px;
    font-size: var(--fs-h4);
    margin-bottom: 0;
}

@include tablet {
    .homeIntro__inner {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }

    .homeIntro__title {
        width: 100%;
        max-width: 620px;
    }

    .homeIntro__description {
        width: 100%;
    }
}

// lastPost
//////////////////////////////////////////////////////
.lastPost {
    position: relative;
    color: $white;
    // background: $gradient-green;
    background: $gradient-green-dark;
}
.lastPost__bg{
    padding: 4rem 0;
    background-image: url(../../images/shape/bg-homeLastPost.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
}

.lastPost__inner {
    box-shadow: 0px 0px 30px 10px rgba(52, 131, 134, 0.05), 0px 0px 10px 0px rgba(14, 107, 110, 0.20);
}

.lastPostGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
}

.lastPostGrid__item {

    &:nth-child(1) { grid-area: 1 / 1 / 2 / 7; min-height: 230px; }
    &:nth-child(2) { grid-area: 2 / 1 / 3 / 3; }
    &:nth-child(3) { grid-area: 2 / 3 / 3 / 5; }
    &:nth-child(4) { grid-area: 2 / 5 / 3 / 7; }
}

@media(max-width: 540px) {

    .lastPostGrid {
        grid-template-columns: 100%;
    }

    .lastPostGrid__item {

        &:nth-child(1) { grid-area: initial; }
        &:nth-child(2) { grid-area: initial; }
        &:nth-child(3) { grid-area: initial; }
        &:nth-child(4) { grid-area: initial; }
    }
}

@media(min-width: 541px) and (max-width: 767px) {

    .lastPostGrid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .lastPostGrid__item {

        &:nth-child(1) { grid-area: initial; }
        &:nth-child(2) { grid-area: initial; }
        &:nth-child(3) { grid-area: initial; }
        &:nth-child(4) { grid-area: initial; }
    }
}

// Shape background
///////////////////////////////////////////////////////
.homeBackground {
    background-image: url('../../images/shape/shape-background-hp.svg');
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 1000px auto;
}

@include tablet {
    .homeBackground{
        background-image: none;
    }
}
