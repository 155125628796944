.pageList{}
.pageList__title{margin-bottom: 1rem;}
.pageList__filters{margin-top: 2rem;}
.pageList__list{margin-top: 2rem;margin-bottom: 2rem;}

.pageList__form{
    --gap: .5rem;
    display: flex; justify-content: flex-start; align-items: flex-start; gap: var(--gap); flex-wrap: wrap;
}
.pageListSearch{position: relative; width: 175px;}
.pageListSearch__loop{
    position: absolute; top: 50%; left: 1rem; transform: translateY(-50%);
    svg{display: block; fill: $info-color;}
}
.pageListSearch__input{width: 100%; padding-left: 3rem;}
.pageList__input{border: none; box-shadow: $shadowInset;

    &:focus-visible {
        outline: solid 1px $green;
    }
}
.pageList__input.--categories{width: 170px;}
.pageList__input.--period{width: 240px;}
.pageList__label{display: inline-block; padding: .5rem  1rem;}
.pageList__submit{min-height: 50px; min-width: 130px; align-self: stretch;}

@mixin pageListFormResp {
    .pageListSearch,
    .pageList__input{flex-grow: 1; min-width: calc(50% - var(--gap));}
    @content;
}

.--sidebar{
    @include medium{
        @include pageListFormResp{}
    }
}
@include tablet{
    @include pageListFormResp{}
}


.pageListTheme .swiper-container{
    overflow: visible;
}
.pageListTheme__header {
    color: $text-secondary;

    margin-bottom: 1rem;

    button {
        color: $action-color;
    }
}

.browseThemeBtn {
    .browseThemeBtn__opened {
        display: none;
    }
    .browseThemeBtn__closed {
        display: block;
    }

    &.--active {
        .browseThemeBtn__opened {
            display: block;
        }
        .browseThemeBtn__closed {
            display: none;
        }
    }
}

.thematicCard {
    padding: 1rem;

    box-shadow: $shadow;

    .thematicCard__title {
        color: $green-infos;
        margin-bottom: 0.5rem;
    }

    .thematicCard__description {margin-bottom: 1rem;}

    .thematicCard__link a {
        color: $action-color;
    }
}

.pageListTheme__list {
    display: none;
}