.b16jumboButton{}
.b16jumboButton__cards{
    --gap: 2rem;
    &.--col1 {--columnsCount: 1;}
    &.--col2 {--columnsCount: 2;}
    &.--col3 {--columnsCount: 3;}
    display: flex; justify-content: flex-start; align-items: stretch; flex-wrap: wrap;
    gap: var(--gap);
}
.b16jumboButtonCard{
    z-index: 1; position: relative;
    flex-grow: 1;
    width: calc((100% / var(--columnsCount)) - var(--gap) + (var(--gap) / var(--columnsCount)));
    min-height: 250px; padding: 2rem;
    text-align: center; color: $white;
    box-shadow: $shadow;

    display: flex;
    align-items: center;
    &:before {
        content: "";
        background-color: $action-color;
    }
}
.b16jumboButtonCard__content{position: relative;}
.b16jumboButtonCard__visual{
    z-index: -1; position: absolute; top: 0; right: 0; bottom: 0; left: 0;
    width: 100%; height: 100%;
    img{display: block; width: 100%; height: 100%; object-fit: cover;}
    &:after{
        content: "";
        position: absolute; top: 0; right: 0; bottom: 0; left: 0;
        background: rgba(0,0,0,0.4);
        // background: linear-gradient(0deg, rgba(20, 58, 60, 0.50) 0%, rgba(20, 58, 60, 0.50) 100%); // pas ouf
    }
}
.b16jumboButtonCard__title{font-size: var(--fs-h3); font-weight: 700;}
.b16jumboButtonCard__subtitle{margin-top: 1rem;}

.--sidebar .b16jumboButton__cards.--col3{--columnsCount:  2;}
@include tablet {
    .--sidebar .b16jumboButton__cards{--columnsCount:  1;}
    .--sidebar .b16jumboButton__cards.--col3{--columnsCount:  1;}
}
@include tablet-sm {
    .b16jumboButton__cards{--columnsCount:  1;}
}