/***** Structure du site *****/
body {
	font-family: $workSans;
	font-size: var(--default-font-size);
	font-weight: var(--default-font-weight);
	line-height: 1.2;

	color: $text-primary;
	background-color: $background;

	&.--hidden {
		overflow: hidden;
		position: relative;
		height: 100%;
	}
}

.container {
	position: relative;
	width: 100%; max-width: var(--container-width); margin: 0 auto; padding: 0 var(--container-padding);
}

a {
	color: $text-primary;
}

.pageContainer{
	&.--sidebar{
		display: flex; justify-content: flex-start; align-items: flex-start; gap: 2rem; flex-wrap: wrap;
		@extend .container;
		.mainContent{
			flex-grow: 1; flex-shrink: 1;
			max-width: calc(100% - var(--sidebar-width) - 2rem);
		}
		.sidebar{
			flex-shrink: 0; 
			width: 100%; max-width: var(--sidebar-width);
		}
		.container {padding-right: 0; padding-left: 0;}
	}
}

.mainContent.--no-space-first {
	.section {
		
		&:first-child {
			margin-top: 1rem;
		}
	}
}

@include tablet {
    .sidebar{padding-top: $spacing-sm; padding-bottom: $spacing-sm;}
}
@include tablet {
	.pageContainer{
		&.--sidebar{
			.sidebar{max-width: 100%;}
			.sidebarThumbnail__visual{display: none;}
			.mainContent{max-width: 100%;}
		}
	}
}