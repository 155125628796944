/* Helpers */

// Position
.p-r {position: relative;}

// Text
.tt-u {text-transform: uppercase;}
.ta-c {text-align: center;}

// Weight
.fw-500 {font-weight: 500;}
.fw-700 {font-weight: 700;}

//Margin
.mt {margin-top: 1rem;}
.mb {margin-bottom: 1rem;}
.mt-0 {margin-top: 0;}
.mb-0 {margin-bottom: 0;}