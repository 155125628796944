/* --------------------------------------------------------
-- Register
-------------------------------------------------------- */

.tpl__register {}

// Introduction
//////////////////////////////////////////////////////
.introduction {

    &.--register {
        background-color: $info-color;
        color: $white;

        background-image: url('../../images/shape/shape-header.svg');
        background-repeat: no-repeat;
        background-position: right -5%;

        .introduction__inner {
            color: $white;
            padding: 5rem 2rem;

            text-align: center;
            text-transform: uppercase;
        }
    }
}

// Register
///////////////////////////////////////////////////////

.tabs__list {
    margin-top: 1rem;

    ul {
        display: flex;
        align-items: center;

        li {

            & + li {
                margin-left: 1rem;
            }
        }
    }
}

.tabs__list-button {
    padding: 1rem;

    border: 1px solid $light-blue-3;

    background-color: $white;

    color: $text-primary;

    &.--active {
        background-color: $action-color;
        border-color: $action-color;
        color: $white;
    }
}

.tabs__container {
    padding: 2rem 0;
}

.tab {
    display: none;

    &.--active {
        display: block;
    }
}

.tab__slider {
    padding-bottom: 1rem;

    .swiper-wrapper {
        height: auto;
    }
}

@include tablet {

    .tab__slider {
        overflow: hidden;
    }

    .introduction.--register .introduction__inner {
        padding: 5rem 0;
    }
}

@include tablet-sm {

    .introduction.--register {
        background-image: none;
    }

    .tabs__list {
        ul {
            flex-direction: column;

            li {
                width: 100%;

                & + li {
                    margin-left: 0;
                    margin-top: 0.7rem;
                }

                button {
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }
}


// RegisterCard
///////////////////////////////////////////////////////
.registerCard {
    position: relative;

    overflow: hidden;

    height: 650px;
    padding: 2rem 1rem;

    background-color: $pale-green;
    box-shadow: 0px 2px 8px 0px rgba(40, 100, 102, 0.15);
}

.registerCard__bg {
    position: absolute;

    bottom: 2%;
    right: -40px;

    font-size: 25rem;
    font-weight: 700;
    font-family: $workSans;

    color: rgba($green-infos, 0.2);
}

.registerCard__inner {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
}

.registerCard__title {
    margin-bottom: 1rem;
}

.registerCard__category {
    padding: 1rem 0.5rem;

    color: $info-color;

    border-bottom: 1px solid $info-color;

    text-transform: uppercase;

    font-size: var(--fs-extra-small);
}

.registerCard__price {
    margin-top: 1rem;

    text-transform: uppercase;
    font-size: 2rem;
}

.registerCard__button {
    margin-top: 1rem;
}

@include mobile {

    .registerCard__bg {
        font-size: 20rem;
        bottom: 5%;
    }
}