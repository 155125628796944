/* --------------------------------------------------------
-- Page hierarchy
-------------------------------------------------------- */

// Colors variations : with acf type field 
//////////////////////////////////////////////////////
.tpl__color {

    //expert-group
    &.--expert-group {
        --template-color: #CF6126; 
        --Gradient-type-Expert: #E3530F;
        --template-gradient: var(--Gradient-type-Expert, linear-gradient(90deg, rgba(0, 0, 0, 0.20) 45.66%, rgba(0, 0, 0, 0.00) 90.27%), #E3530F);
    }
       
    //section
    &.--section {
        --template-color: #FBA624;
        --template-gradient: linear-gradient(0deg, #FBA624, #FBA624), linear-gradient(90deg, rgba(0, 0, 0, 0.2) 45.66%, rgba(0, 0, 0, 0) 90.27%);
    }

    //branch
    &.--branch {
        --template-color: #00836D;
        --template-gradient: linear-gradient(0deg, #00836D, #00836D), linear-gradient(90deg, rgba(0, 0, 0, 0.2) 45.66%, rgba(0, 0, 0, 0) 90.27%);
    }

    //forum
    &.--forum {
        --template-color: #730067;
        --template-gradient: #730067;
    }

    //program
    &.--program {
        --template-color: #5575D9;
        --template-gradient: #5575D9;
    }

    //fida
    &.--fida {
        --template-color: #A60056;
        --template-gradient: #A60056;
    }
}

.hierarchyHeader {
    padding: 1rem 0;
}