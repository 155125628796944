/* --------------------------------------------------------
-- Breadcrumb
-------------------------------------------------------- */

.breadcrumb {
    padding: 1.2rem 0;

    font-size: var(--fs-extra-small);

    color: $light-blue;

    a {
        color: $light-blue;

        transition: color .2s ease;

        &:hover {
            color: $action-color;
        }
    }

    .breadcrumb_last {
        color: $info-color;
        .pageHeader &{color: #fff;}
    }
}