/* --------------------------------------------------------
-- Page header
-------------------------------------------------------- */
$pageHeader_padding: 2rem;
.pageHeader {
    position: relative;
    padding: $pageHeader_padding 0;
    color: $white;
    background-color: $info-color;
    // background-image: url('../../images/shape/shape-header.svg');
    background-repeat: no-repeat;
    background-position: right 15%;
    .breadcrumb{margin-top: -$pageHeader_padding;}
    &:before{
        content: ""; position: absolute; top: 0; right: 0; bottom: 0; left: 0;
        background-image: url('../../images/shape/shape-header2.svg');
        background-repeat: no-repeat;
        background-position: right bottom;
    }
}

.pageHeader__inner{display: flex; justify-content: flex-start; align-items: center; gap: 1rem;}
.pageHeader__content{flex-grow: 1;}
.pageHeader__visuel{
    flex-shrink: 0;
    width: var(--sidebar-width);
    img{object-fit: contain;}
}

.pageHeader__type {
    display: inline-block;

    font-size: var(--fs-extra-small);
    text-transform: uppercase;

    padding: 0.25rem 0.5rem;

    background-color: var(--template-color, $action-color);

    margin-bottom: 0.7rem;
}

.pageHeader__text {
    margin-top: 0.5rem;
}

.pageHeader__creations {
    margin-top: 1rem;
}


// .tpl__listResource .pageHeader {
//     background: linear-gradient(268.45deg, #EBFDFF 12.31%, #DAFBFF 103.39%);
//     color: $text-primary;
//     &:before{
//         content: ""; position: absolute; top: 0; right: 0; bottom: 0; left: 0;
//         background-image: url('../../images/shape/shape-resources.svg');
//         background-repeat: no-repeat;
//         background-position: right bottom;
//     }
// }
// .tpl__listResource .pageHeader .breadcrumb .breadcrumb_last {
//     color: $green-infos;
// }