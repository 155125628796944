 .b06Documents{}
 .b06Documents__list{
	--gap: 1rem;
	--columnsCount:  3;
	display: flex; justify-content: flex-start; align-items: stretch; flex-wrap: wrap;
	gap: var(--gap);
}

.b06Document{
	display: flex; flex-direction: column; justify-content: stretch; align-items: stretch;
	width: calc((100% / var(--columnsCount)) - var(--gap) + (var(--gap) / var(--columnsCount)));
	background: $white; box-shadow: $shadow;
}
.b06Document__content{
	flex-grow: 1; 
	display: flex; flex-direction: column; align-items: stretch; justify-content: space-between;
	padding: 1rem;
}
.b06Document__title{margin-bottom: 1rem;}
.b06Document__pictoList{}
.b06Document__pictoItem{
	display: flex; justify-content: flex-start; align-items: center;
	&+&{margin-top: 1rem;}
	.icon,
	.picto{margin-right: 0.5rem;}
}
.b06Document__footer{
	padding: 1rem;
	border-top: 1px solid $light-blue-3;
}

@include tablet-sm {
	.b06Documents__list{--columnsCount:  1;}
}