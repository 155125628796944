.b02Form{}
.b02Form__container{}

.b02Form__container.--hasImg{
		--gap: 0px;
		--columnsCount:  2;
		display: flex; justify-content: flex-start; align-items: stretch; flex-wrap: wrap;
		gap: var(--gap);
}
.b02Form__container.--hasImg>*{
 	width: calc((100% / var(--columnsCount)) - var(--gap) + (var(--gap) / var(--columnsCount)));
}
.b02Form__title{}
.b02Form__description{margin-bottom: 2rem;}
.b02Form__form{
	padding: 2rem;
	background: $white; box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}
.b02Form__visuel{
	img{display: block; width: 100%; height: 100%; object-fit: cover;}
}

@include tablet-sm {
	.b02Form__container.--hasImg{--columnsCount:  1;}
	.b02Form__visuel{display: none;}
}