/* --------------------------------------------------------
-- Nav secondary
-------------------------------------------------------- */
.navSecondary {
    --navSecondary_fontSize: 1.125rem;
    background: var(--template-gradient, linear-gradient(97.43deg, #004F52 7.08%, #036B6F 116.48%));
}

.navSecondary__inner {
    position: relative;
    display: flex; align-items: stretch; justify-content: space-between; gap: 5px 1.5rem;
}

.navSecondary__list {
    display: flex; align-items: stretch; justify-content: flex-start; gap: 5px 1.5rem;
    min-height: 70px;
    overflow: auto; max-width: 100%;
}
.navSecondary__listItem {
    display: flex; justify-content: center; align-items: center; 
    list-style: none;
}
.navSecondary__link {
    position: relative;
    display: flex; justify-content: center; align-items: center; height: 100%; padding: 5px;
    font-size: var(--navSecondary_fontSize); color: white;
    cursor: pointer;
    &:before {
        content: "";
        position: absolute; left: 0; bottom: 0;
        width: 100%; height: 4px;
        background-color: $white;
        transform: scaleX(0); transform-origin: top right; will-change: transform; transition: transform .3s ease;
    }
    &.--current, &:hover {
        &:before {
            transform-origin: top left;
            transform: scaleX(1);
        }
    }
}
.navSecondary__link [type='radio']{
    position: absolute; top: 0; right: 0; 
    width: 1px; height: 1px; overflow: hidden;
}

.navSecondary__dropdown {z-index: 1;}
.navSecondaryDropdown__button {
    display: flex; align-items: center;
    height: 100%;
    /*font-size: var(--navSecondary_fontSize);*/ text-align: left; color: $white;
}

.navSecondaryDropdown__button__icon {
    display: flex; align-items: center;
    margin-left: 0.5rem;
    transform: rotate(90deg);
}

.navSecondaryDropdown__list {
    position: absolute; top: 100%; right: 0;
    display: none;
    background-color: white; box-shadow: 0px 0px 30px 10px rgba(52, 131, 134, 0.05), 0px 0px 10px 0px rgba(14, 107, 110, 0.20);
    li {
        a {
            display: inline-block; padding: 0.5rem 1rem; width: 100%;
            white-space: nowrap;
            transition: all 0.3s;
            &:hover {
                background-color: $action-color;
                color: $white;
            }
        }
    }
}

@include tablet-sm {
    .navSecondary__inner {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .navSecondary__dropdown {
        padding: 1rem 0;
    }

    .navSecondaryDropdown__list {
        right: auto;
        left: 0;
    }
    .navSecondary__listItem{flex-shrink: 0; max-width: 150px;}
}
@include mobile {
    .navSecondary {--navSecondary_fontSize: 1rem;}
    .navSecondary__list{width: 100%; overflow: auto;}
    // .navSecondary__link{white-space: nowrap;}
}