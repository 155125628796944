.pageSearch{}
.pageSearch__form{
    --gap: .5rem;
    display: flex; justify-content: flex-start; align-items: flex-start; gap: var(--gap); flex-wrap: wrap;
    margin-top: 1rem;
}
.pageSearchSearch{position: relative; width: 480px;}
.pageSearchSearch__loop{
    position: absolute; top: 50%; left: 1rem; transform: translateY(-50%);
    svg{display: block; fill: $info-color;}
}
.pageSearchSearch__input{width: 100%; padding-left: 3rem;}
.pageSearch__input{
    border: none; box-shadow: $shadowInset;
    &:focus-visible {
        outline: solid 1px $green;
    }
}
.pageSearch__input.--categories{width: 170px;}
.pageSearch__input.--period{width: 240px;}
.pageSearch__submit{min-height: 50px; min-width: 130px; align-self: stretch;}
