/* --------------------------------------------------------
-- Header
-------------------------------------------------------- */

.header {
	position: relative;

	background-color: $white;

	border-bottom: 1px solid $info-color;

	z-index: 9;

	.container {
		position: static;
	}
}

.header__inner {
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 35px 0 20px;
}

.headerLeft {
	display: flex; align-items: center;
}

.headerLeft__logo {
	flex-shrink: 0;
	max-height: 35px;
	// img{display: block;}
}
.headerLeft__logo-desktop{display: block;}
.headerLeft__logo-mobile{display: none;}

.headerNav {
	margin-left: 50px;

}
.headerNav__list {
	display: flex; align-items: flex-start; justify-content: flex-start; gap: 5px 20px; flex-wrap: wrap;
}
.headerNav__item {
	&:last-child {
		.headerNav__link {
			color: $green;
			border-radius: 0; background-color: $pale-green;

			&.--active {
				color: $white;
				background-color: $action-color;

				&:hover {
					color: $white;
					background-color: $action-color;
				}
			}

			&:hover {
				color: $action-color;
			}
		}
	}
}
.headerNav__link {
	display: block;
	padding: 8px 8px;

	transition: background-color 0.3s ease;

	border-radius: 30px;

	&.--active {
		background-color: $action-color;
		color: $white;

		&:hover {
			background-color: $action-color;
			color: $white;
		}
	}

	&:hover {
		background-color: $light-blue-4;
	}
}


.headerRight {
	display: flex; align-items: center;
}

.headerRight__item {
	
	& + .headerRight__item {
		position: relative;

		padding-left: 1rem;
		margin-left: 1rem;

		&:before {
			content: "";
			position: absolute; left: 0; top: 0; bottom: 0;
			width: 1px; height: 20px; margin: auto;
			background-color: $green;
		}
	}
}

.headerSearchButton {
	display: flex;
	align-items: center;
	
	color: $text-secondary;

	transition: color .2s ease;

	.headerSearchButton__icon {
		display: flex;
		align-items: center;

		path {
			transition: fill .2s ease;
		}
	}

	.headerSearchButton__text {
		margin-left: 10px;
	}

	&:hover {
		color: $action-color;

		path {
			fill: $action-color;
		}
	}
}

.headerRight__login {margin-left: 30px;}
.headerRight__login-link{
	color: $action-color;
	text-transform: uppercase;
	
	transition: color .2s ease;

	&:hover {
		color: $green;
	}
}

//Navdrop
.headerNavDrop {
	position: absolute;

	top: 100%;
	left: 0;

	width: 100%;

	display: none;

	z-index: 1000;

	.headerNavDrop__layer {
		position: absolute;

		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-color: $white;

		transform-origin: top left;
	}
}

.headerNavDrop__list {
	--items-per-line: 4;

	position: relative;

	display: grid;
	grid-template-columns: repeat(var(--items-per-line), 1fr);
	gap: 2rem;

	padding: 2.5rem 3rem;

	&:before {
		content: "";

		position: absolute;

		top: 0;
		left: 2.5rem;
		right: 2.5rem;

		width: 100%;
		height: 1px;

		background: linear-gradient(268.45deg, #EBFDFF 12.31%, #C5F9FF 103.39%);
	}
}

.headerNavDrop__link.--layout-card {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 295px;
	padding: 10px;

	color: $white;

	font-size: 1.5rem;
	font-weight: 700;

	text-align: center;

	&:before {
		background-color: rgba($black, 0.1);
		transform: skew(25deg) scaleX(0.2);
		transform-origin: top left;
	}

	span {
		position: relative;
		z-index: 1;
	}

	&:hover {
		
		&:before {
			transform: skew(25deg) scaleX(1);
		}
	}

	&.--item-1 {
		background-color: $dark-green;
	}

	&.--item-2 {
		background-color: $green;
	}

	&.--item-3 {
		background-color: $light-blue;
	}

	&.--item-4 {
		background-color: $light-blue-2;
	}
}

.headerNavCardCta {
	position: relative;

	text-align: center;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 100%;
	height: 295px;

	padding: 4rem 1.5rem 1.5rem;

	border: 1px solid $light-blue;

	background-image: url('../../images/shape/shape-card-menu.svg');
	background-repeat: no-repeat;
	background-position: right bottom;
}

.headerNavCardLinks {
	position: relative;

	text-align: center;

	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;

	width: 100%;
	height: 295px;
}

.headerNavCardLinks__link {	
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;

	background-color: $green;

	padding: 1rem;

	font-size: 1.5rem;
	font-weight: 700;

	color: $white;

	&:before {
		background-color: rgba($black, 0.1);
	}

	&.--item-1 {
		background-color: $dark-green;
	}

	&.--item-2 {
		background-color: $green;
	}

	&.--item-3 {
		background-color: $light-blue;
	}

	&.--item-4 {
		background-color: $light-blue-2;
	}
}

.headerNavCardCta__title {
	color: $dark-green-2;

	font-size: 1.3rem;
	font-weight: 700;
}

.headerNavCardCta__button {
	margin: 2rem 0 1rem;
}

.headerNavCardCta__link a {
	color: $action-color;
}

.headerNavDrop__title {
	display: inline-block;

	color: $green;
	font-size: var(--fs-small);

	background: $gradient-light-blue;

	padding: 0.2rem 0.5rem;

	margin-bottom: 1rem;
}

.headerNavSubdrop {
	li {
		& + li {
			margin-top: 1rem;
		}
	}
}

.headerNavSubdrop__link {
	display: flex; align-items: center; gap: 0.25rem;

	will-change: transform;

	transition: color 0.2s ease;

	svg {
		width: 5px;
	}

	&:hover {
		color: $action-color;

		.headerNavSubdrop__link__text {
			transform: translateX(5px);
		}
	}
}

.headerNavSubdrop__link__text {
	transition: transform 0.3s ease;
}

.headerLeft__burger {
	display: flex; align-items: center;
}

.burgerBtn {
	position: relative;
	width: 26px;  height: 18px;  margin-top: -3px;
	span {
		position: absolute; left: 0;
		width: 100%; height: 3px;
		background-color: $text-primary;
		&:nth-child(1) {
			top: 0;
			transition: transform var(--transition-config);
		}
		&:nth-child(2){
			top: 50%;
		}
		&:nth-child(3){
			top: 100%;
			transition: transform var(--transition-config);
		}
	}

	// &.--active {

	// 	span {

	// 		&:nth-child(1){
	// 			transform: translateY(8px) rotate(45deg);
	// 		}

	// 		&:nth-child(2){
	// 			opacity: 0;
	// 		}

	// 		&:nth-child(3){
	// 			transform: translateY(-10px) rotate(-45deg);
	// 		}
	// 	}
	// }
}

.langSwitcher__btn {
	display: flex;
	align-items: center;
	gap: 6px;

	text-transform: uppercase;
	color: $action-color;

	transition: color .2s ease;

	path {
		transition: fill .2s ease;
	}

	&:hover {
		color: $green;

		path {
			fill: $green;
		}
	}

	&.--active {
		.langSwitcher__btn__icon {
			transform: rotate(-90deg);
		}
	}
}

.langSwitcher__btn__icon {
	transform: rotate(90deg);

	transition: transform 0.3s ease;
}

.langSwitcher__list {
	position: absolute;

	display: none;

    top: calc(100% + 1rem);
	left: 0;

    background-color: white;

    padding: 0.5rem 1rem;
	
    width: 70px;

    text-transform: uppercase;

    z-index: 1;

	box-shadow: 0px 10px 8px 0px rgba(29, 50, 51, 0.15);
	transform-origin: top left;

	li {

		& + li {
			margin-top: 5px;
		}

		a {
			color: $action-color;
			transition: color .2s ease;

			&:hover {
				color: $green;
			}
		}
	}
}

.userMenu__button {
	text-transform: uppercase;
	color: $action-color;

	width: 40px;
	height: 40px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 100%;
	background: $light-blue-4;

	transition: all .3s ease;

	&:hover {
		background: $action-color;
		color: $white;
	}
}

.userMenu__content {
	position: absolute;

	top: 100%;
	right: 0;

	padding: 1rem;

	width: 220px;
	height: 270px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	display: none;

	transform-origin: top left;
}

.userMenu__layer {
	position: absolute;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-color: $white;

	background-image: url(../../images/shape/shape-card-menu.svg);
    background-repeat: no-repeat;
    background-position: right bottom;
	background-size: 100%;

	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	transform-origin: top left;
}

.userMenu__title {
	text-transform: uppercase;
	font-weight: 500;

	color: #2F3636;

	margin-bottom: 1rem;
}

.userMenu__item {
	display: flex;
	justify-content: space-between;

	font-size: 12px;

	color: #2F3636;

	margin-bottom: 0.25rem;
}

.userMenu__item__label {
	color: #4D6161;
}

.userMenu__logout{margin-top: 2rem;}

.userMenu__footer{}

@include medium {
	.headerNav{margin-left: 25px;}
	.headerNav__list{gap: 5px 10px;}
}
@media (max-width: 1024px) {
	.headerSearchButton__text{display: none;}
}
@include tablet {
	.header__inner {
		padding: 20px 0;
	}

	.headerLeft__nav {
		display: none;
	}

	.headerLeft__logo {
		// display: none;
		order: 2; margin-left: 1rem;
	}

	.headerSearchButton__text {
		display: none;
	}
	.headerLeft__logo-desktop{display: none;}
	.headerLeft__logo-mobile{display: block;}
}

@include tablet-min {

	.headerLeft__burger {
		display: none;
	}
}