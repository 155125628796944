/* --------------------------------------------------------
-- Mobile nav
-------------------------------------------------------- */

.mobileNav {
    // zindex d l'excès pour passer par dessus la bar admin WP
    z-index: 1000000; position: fixed; top: 0; left: 0;
    display: flex; flex-direction: column;
    display: none;
    width: 90%; max-width: 450px; height: 100%; padding: 1rem;
    background-color: $white;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

}
.mobileNav__overlay{
    z-index: 900000;
    position: absolute; top: 0; right: 0; left: 0; bottom: 0;
    display: none;
    background: rgba(0,0,0,.5);
    opacity: 0;
}

.mobileNav__header {
    display: flex; align-items: center; justify-content: space-between;
}

.mobileNav__header__logo {
    max-width: 80px;
}

.mobileNav__content {
    flex: 1;
    margin-top: 2rem;
}

.mobileNav__content__inner {
    display: flex; flex-direction: column; justify-content: space-between;
    height: 100%;
}

.mobileNav__nav{
    margin-bottom: 2rem;
}

.mobileNavMenu {
    & > li {
        & + li {
            margin-top: 1.2rem;
        }
    }
}

.mobileNavMenu__item__link {
    display: flex; align-items: center; justify-content: space-between;
    width: 100%;
    font-size: 1.5rem; font-weight: 700; color: $text-primary;
    &.--active {
        .mobileNavMenu__chevron {
            transform: rotate(0);
        }
    }
}

.mobileNavDrop {
    display: none; padding-right: 0; padding-left: 1rem; margin-top: 1rem;

}

.mobileNavDrop__list {
    & > li {
        & + li {
            margin-top: 0.5rem;
        }
    }
}

.mobileNavDrop__title {
    display: flex; align-items: center; justify-content: space-between;
	padding: 0.5rem 0 0.5rem; margin-bottom: 0.5rem;
    &.--active {
        .mobileNavMenu__chevron {
            transform: rotate(0);
        }
    }
}

.mobileNavSubdrop {
    padding-left: 1rem;
    display: none;
    li {

        & + li {
            margin-top: 0.5rem;
        }
    }
}

.mobileNavMenu__chevron {
    display: flex; align-items: center;

    transform: rotate(-90deg);
    will-change: transform;
    transition: transform var(--transition-config);
}

.mobileNav__footer {
    padding: 1rem;

    background-color: #F1F1F1;
}

.mobileNav__footer__title {
    font-size: var(--fs-small); font-weight: 700;
    margin-bottom: 0.5rem;
}

.mobileNav__footer__text {
    margin-bottom: 1rem;
}