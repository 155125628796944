/* --------------------------------------------------------
-- NewsCard
-------------------------------------------------------- */
.newsCard {
    background-color: $white;
    color: $text-primary;

    @media(min-width: 768px){
     
        &.--large {
            .newsCard__content__excerpt {
                display: -webkit-box; -webkit-box-orient: vertical;
            }
            .newsCard__inner {
                flex-direction: row;
            }
            .newsCard__image {
                width: calc((100% / 3) - 0.65rem); height: 100%;
            }
            .newsCard__content {
                align-self: center;
            }
        }

    }
}
.newsCard.--large .newsCard__content__title {font-size: var(--fs-h3);min-height: auto;}
.newsCard__inner {
    display: flex; justify-content: flex-start; align-items: flex-start;flex-direction: column;
    width: 100%; height: 100%;
}
.newsCard__image {
    flex-shrink: 0;
    position: relative;
    width: 100%;
    aspect-ratio: 16/7;
    img {
        position: absolute; top: 0; left: 0;
        width: 100%; height: 100%;
        object-fit: cover; object-position: center;
    }
}
.newsCard__content {
    flex-grow: 1; align-self: flex-start;
    padding: 15px 15px;
}
.newsCard__content__category {
    display: inline-block; padding: 5px 8px; margin-bottom: 15px;
    color: $green;
    background-color: $light-grey;
}
.newsCard__content__title {
    font-size: var(--fs-h4);
    margin-bottom: 15px;
    min-height: 65px;
}
.newsCard__content__excerpt {
    margin-bottom: 15px;
    // limit 3 lines
    display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3; overflow: hidden;
    display: none;
}
.newsCard__content__button {
    span {font-size: var(--fs-small); color: $action-color;}
}
@include tablet-sm {
    .newsCard__inner {flex-direction: column;}
    .newsCard__image {width: 100%; aspect-ratio: 16/9;}
}
@include tablet-min-max {
    .newsCard__image {min-width: 240px;}
}