.loader{
    .--loading &:before,
    &.--loading:before{
        content: "";
        display: inline-block; width: 1.5em; height: 1.5em; vertical-align: middle;
        border: .1em solid $green-infos; border-left: .1em solid transparent;
        border-radius: 100%;
        animation: rotateLoader 1s infinite;
    }
}
@keyframes rotateLoader{
    0%{transform:rotate(0);}
    100%{transform:rotate(360deg);}
}