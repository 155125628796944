/**************************************************
* AUTOFILL
**************************************************/
input:-webkit-autofill,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: $white !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    background-color: transparent !important;
    // Trick to avoid style applied to "-internal-autofill-selected"
    transition: background-color 600000s 0s, color 600000s 0s !important;
}

.input{
    height: 50px; padding: 1rem;
    color: $text-primary;
    border: 1px solid $inputColor; border-radius: 0; background: $white;
}
.input::placeholder{color: $text-secondary;}
.caretSelect{
    padding-right: 2rem;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='17' viewBox='0 0 16 17' fill='none'%3E%3Cpath d='M13 6.5L8 11.5L3 6.5' stroke='%23008D97' stroke-width='1.14286' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-position: 100% 50%;
    background-repeat: no-repeat;
}

.gform_wrapper.gravity-theme {
    input[type="text"], 
    input[type="password"], 
    input[type="email"], 
    input[type="url"], 
    input[type="date"], 
    input[type="month"], 
    input[type="time"], 
    input[type="datetime"], 
    input[type="datetime-local"], 
    input[type="week"], 
    input[type="number"], 
    input[type="search"], 
    input[type="tel"], 
    input[type="color"],
    select{max-width: 264px!important; @extend .input;}
    textarea{@extend .input;}
    select{@extend .caretSelect;}
    .gfield_label{font-weight: 300;}
    input[type=submit]{
        border: none;
        &:hover,
        &:focus-visible{color: #fff;}
    }
}